import Averon81 from "../../media/rumah/averon/tipe8 (1).png";
import Averon82 from "../../media/rumah/averon/tipe8 (2).png";
import Averon83 from "../../media/rumah/averon/tipe8 (3).png";
import Averon91 from "../../media/rumah/averon/tipe9 (1).png";
import Averon92 from "../../media/rumah/averon/tipe9 (2).png";
import Averon93 from "../../media/rumah/averon/tipe9 (3).png";
import Averon101 from "../../media/rumah/averon/tipe10 (1).png";
import Averon102 from "../../media/rumah/averon/tipe10 (2).png";
import Averon103 from "../../media/rumah/averon/tipe10 (3).png";

const tipe8 = {
  name: "Averon 8",
  price: "14.9",
  kamar: "4+1",
  kamarMandi: "4+1",
  luasTanah: "128",
  luasBangunan: "149",
  dp: "Tanpa DP",

  images: [Averon81, Averon82, Averon83],
  whatsapp:
    "https://api.whatsapp.com/send/?phone=&text=Halo+Addy%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Averon8)%20https://marketing-kotawisata.com/&type=phone_number&app_absent=0",
};

const tipe9 = {
  name: "Averon 9",
  price: "19.7",
  kamar: "4+1",
  kamarMandi: "3+1",
  luasTanah: "162",
  luasBangunan: "187",
  dp: "Tanpa DP",

  images: [Averon91, Averon92, Averon93],
  whatsapp:
    "https://api.whatsapp.com/send/?phone=&text=Halo+Addy%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Averon9)%20https://marketing-kotawisata.com/&type=phone_number&app_absent=0",
};

const tipe10 = {
  name: "Averon 10",
  price: "27.5",
  kamar: "4+1",
  kamarMandi: "4+1",
  luasTanah: "200",
  luasBangunan: "267",
  dp: "Tanpa DP",

  images: [Averon101, Averon102, Averon103],
  whatsapp:
    "https://api.whatsapp.com/send/?phone=&text=Halo+Addy%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Averon10)%20https://marketing-kotawisata.com/&type=phone_number&app_absent=0",
};

const rumahAveron = [tipe8, tipe9, tipe10];

export default rumahAveron;
