import React from "react";
import "./Surrounding.scss";
import surroundingImages from "../../media/data/SurroundingImages";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Surrounding = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="surounding" id="surrounding">
      <h1 className="surrounding-title">SURROUNDING AREA</h1>
      <Slider {...settings}>
        {surroundingImages.map((slide, index) => (
          <div className="slider-container " key={index}>
            <img src={slide} alt="fasilitas" className="image" />
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default Surrounding;
