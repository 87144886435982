import React from "react";
import "./Rumahbaru.scss";
import rumah from "../../media/data/RumahBaru";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faBed,
  faHouse,
  faMaximize,
  faShower,
} from "@fortawesome/free-solid-svg-icons";

const Rumahbaru = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyload: true,
  };

  const wapromo = (rumahObj) => {
    const baseLink = "https://api.whatsapp.com/send/?phone=6281287877618";
    const text =
      "Halo+Addy%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20";
    const domainLink = "https://marketing-kotawisata.com/";
    const fullLink = `${baseLink}&text=${text}(${rumahObj.name})+${domainLink}`;
    window.open(fullLink, "_blank");
  };
  return (
    <div id="Averon" className="rumahbaru">
      <h1 className="section-title">NEW LAUNCHING AVERON</h1>
      <div className="container-card">
        {rumah.map((item, index) => (
          <div className="card" key={index}>
            <Slider {...settings}>
              {item.images.map((imagePath) => (
                <img src={imagePath} alt="" className="kartu-gambar" />
              ))}
            </Slider>

            <div className="card-description">
              <div>
                <h2>{item.name}</h2>
                <h3>Start From</h3>
                <div className="price">{item.price}</div>
                <h3>Juta/Bulan</h3>
              </div>
              <div className="highlight">{item.dp}</div>
              <div className="gridspek">
                <FontAwesomeIcon icon={faMaximize} />
                <span>&nbsp;: {item.luasTanah}m²</span>
                <FontAwesomeIcon icon={faHouse} />
                <span>&nbsp;: {item.luasBangunan}m²</span>
                <FontAwesomeIcon icon={faBed} />
                <span>&nbsp;: {item.kamar}</span>
                <FontAwesomeIcon icon={faShower} />
                <span>&nbsp;: {item.kamarMandi}</span>
              </div>
              <div className="containerwhatsapp">
                <button onClick={() => wapromo(item)} className="whatsapp">
                  <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Rumahbaru;
