import mississipi1 from "../../media/rumah/1-1.webp";
import mississipi2 from "../../media/rumah/1-2.webp";
import mississipi3 from "../../media/rumah/1-3.webp";
import mississipi4 from "../../media/rumah/1-4.webp";
import mississipi5 from "../../media/rumah/1-5.webp";
import mississipi6 from "../../media/rumah/1-6.webp";
import mississipi7 from "../../media/rumah/1-7.webp";
import mississipi8 from "../../media/rumah/1-8.webp";
import mississipi9 from "../../media/rumah/1-9.webp";
import mississipi10 from "../../media/rumah/1-10.webp";
import mississipi11 from "../../media/rumah/1-11.webp";
import vermont1 from "../../media/rumah/2-1.webp";
import vermont2 from "../../media/rumah/2-2.webp";
import vermont3 from "../../media/rumah/2-3.webp";
import vermont4 from "../../media/rumah/2-4.webp";
import vermont5 from "../../media/rumah/2-5.webp";
import vermont6 from "../../media/rumah/2-6.webp";
import vermont7 from "../../media/rumah/2-7.webp";
import vermont8 from "../../media/rumah/2-8.webp";
import vermont9 from "../../media/rumah/2-9.webp";
import vermont10 from "../../media/rumah/2-10.webp";
import vermont11 from "../../media/rumah/2-11.webp";
import atherton1 from "../../media/rumah/3-1.webp";
import atherton2 from "../../media/rumah/3-2.webp";
import atherton3 from "../../media/rumah/3-3.webp";
import atherton4 from "../../media/rumah/3-4.webp";
import atherton5 from "../../media/rumah/3-5.webp";
import atherton6 from "../../media/rumah/3-6.webp";
import atherton7 from "../../media/rumah/3-7.webp";
import atherton8 from "../../media/rumah/3-8.webp";
import atherton9 from "../../media/rumah/3-9.webp";
import atherton10 from "../../media/rumah/3-10.webp";
import atherton11 from "../../media/rumah/3-11.webp";
import atherton12 from "../../media/rumah/3-12.webp";
import davinci1 from "../../media/rumah/4-1.webp";
import davinci2 from "../../media/rumah/4-2.webp";
import richmond1 from "../../media/rumah/Richmond (1).jpeg";
import richmond2 from "../../media/rumah/Richmond (2).jpeg";
import richmond3 from "../../media/rumah/Richmond (3).jpeg";
import richmond4 from "../../media/rumah/Richmond (4).jpeg";
import richmond5 from "../../media/rumah/Richmond (5).jpeg";
import richmond6 from "../../media/rumah/Richmond (6).jpeg";
import richmond7 from "../../media/rumah/Richmond (7).jpeg";
import richmond8 from "../../media/rumah/Richmond (8).jpeg";
import richmond9 from "../../media/rumah/Richmond (9).jpeg";
import richmond10 from "../../media/rumah/Richmond (10).jpeg";
import richmond12 from "../../media/rumah/Richmond (11).jpeg";
import richmond13 from "../../media/rumah/Richmond (12).jpeg";
import richmond14 from "../../media/rumah/Richmond (13).jpeg";
import richmond15 from "../../media/rumah/Richmond (14).jpeg";
import richmond16 from "../../media/rumah/Richmond (15).jpeg";
import richmond17 from "../../media/rumah/Richmond (16).jpeg";
import richmond18 from "../../media/rumah/Richmond (17).jpeg";
import richmond19 from "../../media/rumah/Richmond (18).jpeg";
import richmond20 from "../../media/rumah/Richmond (19).jpeg";
import richmond21 from "../../media/rumah/Richmond (20).jpeg";
import richmond22 from "../../media/rumah/Richmond (21).jpeg";

const mississipi = {
  name: "mississipi",
  price: "11",
  kamar: "3+1",
  kamarMandi: "3+1",
  luasTanah: "100",
  luasBangunan: "200",
  dp: "tanpa dp",

  images: [
    mississipi1,
    mississipi2,
    mississipi3,
    mississipi4,
    mississipi5,
    mississipi6,
    mississipi7,
    mississipi8,
    mississipi9,
    mississipi10,
    mississipi11,
  ],
  whatsapp:
    "https://api.whatsapp.com/send/?phone=&text=Halo+Addy%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(promo)%20https://marketing-kotawisata.com/&type=phone_number&app_absent=0",
};

const vermont = {
  name: "vermont",
  price: "10,3",
  kamar: "3+1",
  kamarMandi: "3+1",
  luasTanah: "91",
  luasBangunan: "110",
  dp: "tanpa dp",
  images: [
    vermont1,
    vermont2,
    vermont3,
    vermont4,
    vermont5,
    vermont6,
    vermont7,
    vermont8,
    vermont9,
    vermont10,
    vermont11,
  ],
  whatsapp:
    "https://api.whatsapp.com/send/?phone=6281287877618&text=Halo+Addy%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(promo)%20https://marketing-kotawisata.com/&type=phone_number&app_absent=0",
};

const atherton = {
  name: "atherton",
  price: "33",
  kamar: "4+1",
  kamarMandi: "4+1",
  luasTanah: "180",
  luasBangunan: "237",
  dp: "tanpa dp",
  images: [
    atherton1,
    atherton2,
    atherton3,
    atherton4,
    atherton5,
    atherton6,
    atherton7,
    atherton8,
    atherton9,
    atherton10,
    atherton11,
    atherton12,
  ],
  whatsapp:
    "https://api.whatsapp.com/send/?phone=6281287877618&text=Halo+Addy%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(promo)%20https://marketing-kotawisata.com/&type=phone_number&app_absent=0",
};

const davinci = {
  name: "davinci (legenda wisata)",
  price: "8",
  kamar: "2",
  kamarMandi: "2",
  luasTanah: "96",
  luasBangunan: "70",
  dp: "subsidi dp",
  images: [davinci1, davinci2],
  whatsapp:
    "https://api.whatsapp.com/send/?phone=6281287877618&text=Halo+Addy%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(promo)%20https://marketing-kotawisata.com/&type=phone_number&app_absent=0",
};
const richmond = {
  name: "Richmond",
  price: "37",
  kamar: "4",
  kamarMandi: "4",
  luasTanah: "200",
  luasBangunan: "332",
  dp: "subsidi dp 10%",
  images: [
    richmond1,
    richmond2,
    richmond3,
    richmond4,
    richmond5,
    richmond6,
    richmond7,
    richmond8,
    richmond9,
    richmond10,
    richmond12,
    richmond13,
    richmond14,
    richmond15,
    richmond16,
    richmond17,
    richmond18,
    richmond19,
    richmond20,
    richmond21,
    richmond22,
  ],
  whatsapp:
    "https://api.whatsapp.com/send/?phone=6281287877618&text=Halo+Addy%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(promo)%20https://marketing-kotawisata.com/&type=phone_number&app_absent=0",
};

const rumah = [mississipi, vermont, atherton, davinci, richmond];

export default rumah;
