import React from "react";
import "./Fasilitas.scss";
import fasilitasImages from "../../media/data/FasilitasImages";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Fasilitas = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="fasilitas" id="fasilitas">
      <h1 className="fasilitas-title">FASILITAS PREMIUM KAWASAN</h1>
      <div className="slider-container ">
        <Slider {...settings}>
          {fasilitasImages.map((slide, index) => (
            <img src={slide} alt="fasilitas" className="image" />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Fasilitas;
