import React from "react";
import "./Newlaunch.scss";
import promo from "./averonhs.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Rumahbaru from "../Rumahbaru/Rumahbaru";

const Newlaunch = () => {
  const pointdata = [
    "Free DP 10%",
    "Free Biaya KPR",
    "Free Solar Panel",
    "Free Smarthome System",
    "Free CCTV",
  ];
  const wapromo = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281287877618&text=Halo+Addy%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(promo+averon)%20https://marketing-kotawisata.com/&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="newlaunchcont" id="newlaunch">
      <div className="center" id="promo-richmond">
        <div className="brosur">
          <img alt="promorichmond" className="img" src={promo} />
        </div>
        <div className="promoitem">
          <h3 className="title">Promo New Launch Averon</h3>
          {pointdata.map((item, index) => (
            <div className="point">
              <FontAwesomeIcon size="lg" icon={faCheckDouble} />
              &nbsp;
              {item}
            </div>
          ))}
          <div className="wabutton">
            <button onClick={wapromo} className="button">
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp; Dapatkan Promo Terbaru
            </button>
          </div>
        </div>
      </div>
      <Rumahbaru />
    </div>
  );
};

export default Newlaunch;
