import React from "react";
import "./Footer.scss";
import logo from "../../media/kota-wisata.webp";

const Footer = () => {
  return (
    <footer id="footer" className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img
            className="gambar-logo"
            src={logo}
            alt="logo-Bukit-Podomoro-Jakarta"
          />
        </div>
        <div className="container-deskripsi">
          <h2 className="title">Marketing Gallery</h2>
          <div className="alamat">
            <p>
              Jl. Wisata Utama No.3, Ciangsana, Kec. Gn. Putri, Kabupaten Bogor,
              Jawa Barat 16968
            </p>
          </div>
          <div className="contact">Contact Marketing : +6281287877618</div>
          <div className="privasi">
            <a href="/privacy-policy"> Privacy Policy</a>
          </div>
        </div>
      </div>
      <div className="container-bawah">
        KOTA WISATA CIBUBUR . 2023 <br />
        <small>Powered by : Linktown</small>
      </div>
    </footer>
  );
};

export default Footer;
