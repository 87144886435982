import React from "react";
import "./Header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  const wapromo = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281287877618&text=Halo+Addy%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20pada%20Kota%20Wisata%20https://marketing-kotawisata.com/&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <section id="header" className="section-header">
      <div className="container-header">
        {/* <img src={landmark} alt="" /> */}
        <h3>WELCOME TO</h3>
        <h1 className="section-title">KOTA WISATA CIBUBUR</h1>
        <div className="button-wa-promo">
          <button onClick={wapromo} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} />
            <span>Hubungi Kami</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Header;
